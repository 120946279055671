@import "./colors";

@mixin btn {
	padding: 8px 18px;
	font-size: 0.9em;
	text-decoration: none;
	border-radius: 5px;
	border: 0.5px solid rgba($color: $primary-color, $alpha: 0.3);
	background-color: transparent !important;
	transition: all 0.1s linear;

	// background-color: $default-color !important;
	//TODO: temporarily apply styles when clicked
}

.app-btn {
	@include btn();

	&:hover {
		background-color: $primary-color !important;
		color: whitesmoke !important;
	}
}

.btn-text {
	border: 0px;
	color: $primary-color;
	font-weight: 600;

	&:hover {
		background-color: rgba($color: $secondary-color, $alpha: 0.1) !important;
		color: #333 !important;
	}
}

.btn-prim {
	@include btn();
	background-color: $primary-color !important;
	color: whitesmoke;

	&:hover {
		color: whitesmoke;
		background-color: $secondary-color !important;
		border: 1px solid $secondary-color;
	}
}

.btn-secondary {
	@include btn();
	background-color: $accent-color !important;
	color: black;

	&:hover {
		color: whitesmoke;
		background-color: $accent-color-dark !important;
		border: 1px solid $accent-color-dark;
	}
}
