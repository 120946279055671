@import "../shared/colors";

.app-card {
	border: 1px solid rgba($color: $secondary-color, $alpha: 0.4) !important;
	padding: 10px !important;
	border-radius: 15px;

	&.filter-card {
		border-radius: 20px !important;
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}
}
