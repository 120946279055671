@import "./scss/shared/utilities";
@import "./scss/shared/button";
@import "./scss/shared/colors";
@import "./scss/components/cards";
@import "./scss/components/navs";
@import "./scss/components/components";
@import "./scss/components/portals";

//app level styling
* {
  margin: 0;
  box-sizing: content-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

// mobile-first
.app-layout {
  background-color: $default-color !important;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh !important;
  // background-color: $default-color;

  & .layout-outlet {
    background-color: whitesmoke !important;
    min-height: 100vh;
    border-radius: 0px;
    padding: 3%;
    position: relative !important;
  }

  // & .content {
  // margin-top: 5%;
  // }
}

// landscape upwards (desktop, tablet, wide screen etc)
@media screen and (min-width: 600px) {
  .app-layout {
    & .content {
      margin-top: 2%;
      position: relative;
    }

    & .layout-outlet {
      border-radius: 5px;
      border: 2px solid $ahotopa-lite-green;
    }
  }
}

// z-indexes
// charm-bar = 500
// mobile-nav = 450
// desktop-nav = 50
// top-nav = 60
