.portal {
  //   width: 100vw;
  background-color: #ddd;
  min-height: 100vh;

  .inner-layer {
    // width: 80vw;
    background-color: #f5f5f5;

    .content {
      // min-height: 80vh;
      padding-bottom: 70px;
    }
  }
}

@media screen and (min-width: 768px) {
  .portal {
    // overflow: hidden;

    .inner-layer {
      background-color: whitesmoke;
      position: relative;
      border-radius: 5px;
    }
  }
}
