@import "../shared/colors";

.charm-bar {
  top: 0px;
  z-index: 500;
  background-color: whitesmoke;

  & .charm-content {
    top: 0;
  }
}

// pages
.quick-page,
.video-consultation {
  background-color: $default-color !important;
  // min-height: 100vh;
  // position: fixed;
  // overflow-x: auto;

  // & .content {
  // margin-top: 2vh;
  // border-radius: 5px;
  // }

  // .consultation-comp {
  //   // position: relative;
  // }

  #videoFrame {
    position: fixed;
  }

  iframe {
    width: 65vw;
    height: 100vh !important;
  }
}

.patient-finder {
  min-height: 80vh !important;

  .results {
    overflow-y: auto;
  }
}

.user-profile {
  & .user-avatar {
    min-width: 30vw !important;
    min-height: 15vh !important;
    margin-top: -60px;
    border: 1px solid $primary-color;
  }
}

.consultation-detail {
  .section-header {
    color: $primary-color !important;
    font-weight: 500;
  }

  .layer-header {
    color: #999 !important;
  }
}

.roles-page {
  height: 50vh;
  // position: relative;

  .content {
    min-height: 70vh;
    // position: absolute;
  }

  .permissions {
    overflow-y: auto;
    border-left: 1px solid $default-color;
  }
}

// landscape upwards (desktop, tablet, wide screen etc)
@media screen and (min-width: 600px) {
  .quick-page {
    & .content {
      margin-top: 10vh;
      background-color: whitesmoke;
    }
  }

  .user-profile {
    & .user-avatar {
      min-width: 10vw !important;
      min-height: 21vh !important;
      margin-top: -60px;
      border: 2px solid $primary-color;
    }
  }
}
