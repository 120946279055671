@import "./colors";

.app-color {
  color: $primary-color !important;
}

.app-color-dark {
  color: $primary-color-dark !important;
}

.app-color-secondary {
  color: $secondary-color !important;
}

.app-bg-default {
  background-color: $default-color;
}

.app-bg-primary {
  background-color: $primary-color !important;
  transition: all 0.2s linear;

  &:hover {
    background-color: $primary-color-dark !important;
  }
}

.ahotopa-bg-primary {
  background-color: $ahotopa-green !important;
  transition: all 0.2s linear;

  &:hover {
    background-color: $ahotopa-lite-green !important;
  }
}

.ahotopa-bg-secondary {
  background-color: $ahotopa-lite-green !important;
  transition: all 0.2s linear;

  &:hover {
    background-color: $ahotopa-green !important;
  }
}

.app-bg-secondary {
  background-color: $secondary-color;

  transition: all 0.2s linear;

  &:hover {
    background-color: $primary-color !important;
  }
}

.selected-item {
  background-color: whitesmoke;
}

.app-border {
  border: 1px solid #ccc !important;
}

@mixin background() {
  background-color: $primary-color !important;
  color: whitesmoke !important;
}

.hover-hand {
  &:hover {
    cursor: pointer !important;
  }
}

.hover {
  &:hover {
    cursor: pointer !important;
    @include background();
  }
}

.is-selected {
  background-color: $primary-color !important;
  color: whitesmoke !important;
}

.page-title {
  color: $primary-color !important;
  font-weight: bold;
  text-transform: uppercase;
}

// ant design steps component
.ant-steps-item-icon,
.ant-steps-icon {
  color: whitesmoke !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: $primary-color !important;
}

.app-tile {
  border: 1px solid $primary-color-dark;
  border-radius: 5px;
  text-align: center;

  * {
    color: #666 !important;
  }
}

.summary-card {
  // border: 1px solid rgba($color: $primary-color, $alpha: 0.5);
  // border: 1px solid #ccc;
  // background-color: whitesmoke;
  padding: 8px 0 8px 0;
  border-radius: 5px;
  text-align: center;
  transition: all 0.25s linear;
  width: 400px;

  & .data {
    color: $primary-color !important;
    font-weight: bolder;
    font-size: 1.8em;
    margin: 0px;
  }

  & .card-label {
    color: #777 !important;
    font-size: 0.8em;
  }

  &:hover {
    border-color: $primary-color;
    cursor: pointer;
  }
}

.card-danger {
  animation: blinkRed 1s linear infinite;

  .data,
  .card-label {
    color: whitesmoke !important;
  }
}

.card-warning {
  animation: blinkGold 2s linear infinite;

  .data,
  .card-label {
    color: #333 !important;
  }
}

.card-alert {
  animation: blinkGreen 3s linear infinite;

  .data,
  .card-label {
    color: #333 !important;
  }
}

@keyframes blinkRed {
  50% {
    background-color: darkred;
    color: whitesmoke !important;
  }
}
@keyframes blinkGold {
  50% {
    background-color: goldenrod;
  }
}
@keyframes blinkGreen {
  50% {
    background-color: $primary-color;
  }
}

@mixin tileHover {
  border-color: $primary-color;
  background-color: $primary-color;

  * {
    color: $default-color !important;
  }
}

.action-tile {
  transition: all 0.25s linear;

  &:hover {
    @include tileHover();
  }
}

.tile-active {
  @include tileHover();
}

.hover {
  &:hover {
    cursor: pointer !important;
  }
}

.h-scroll {
  display: flex;
  overflow-x: scroll !important;
  padding-bottom: 10px;
}

// landscape upwards (desktop, tablet, wide screen etc)
@media screen and (min-width: 600px) {
  .h-scroll {
    overflow-x: hidden !important;
    scrollbar-gutter: 1px !important;
    padding-bottom: 1px;
  }
}
