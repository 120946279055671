$default-color: #ddd;
$primary-color: #0991cc;
$primary-color-dark: #043c54;
$secondary-color: rgb(29, 79, 154); // was primary-color-light
$success-color: rgb(7, 87, 207);

$ahotopa-green: #0977a6;
$ahotopa-lite-green: #0aa0e0;
$ahotopa-red: #e21c22;

$accent-color: #efca00;
$accent-color-dark: #776502;


// original colors
// $primary-color: #555fd2;
// $primary-color-dark: #0c1a35;
// $secondary-color: #193469;

// old

// $primary-color: #193469;
// $secondary-color: #22468f;
// $secondary-color: #346bdb;
// $default-color: #cbd0e0;
// $default-color: #eeeef0;
