.app-color {
  color: #0991cc !important;
}

.app-color-dark {
  color: #043c54 !important;
}

.app-color-secondary {
  color: rgb(29, 79, 154) !important;
}

.app-bg-default {
  background-color: #ddd;
}

.app-bg-primary {
  background-color: #0991cc !important;
  transition: all 0.2s linear;
}
.app-bg-primary:hover {
  background-color: #043c54 !important;
}

.ahotopa-bg-primary {
  background-color: #0977a6 !important;
  transition: all 0.2s linear;
}
.ahotopa-bg-primary:hover {
  background-color: #0aa0e0 !important;
}

.ahotopa-bg-secondary {
  background-color: #0aa0e0 !important;
  transition: all 0.2s linear;
}
.ahotopa-bg-secondary:hover {
  background-color: #0977a6 !important;
}

.app-bg-secondary {
  background-color: rgb(29, 79, 154);
  transition: all 0.2s linear;
}
.app-bg-secondary:hover {
  background-color: #0991cc !important;
}

.selected-item {
  background-color: whitesmoke;
}

.app-border {
  border: 1px solid #ccc !important;
}

.hover-hand:hover {
  cursor: pointer !important;
}

.hover:hover {
  cursor: pointer !important;
  background-color: #0991cc !important;
  color: whitesmoke !important;
}

.is-selected {
  background-color: #0991cc !important;
  color: whitesmoke !important;
}

.page-title {
  color: #0991cc !important;
  font-weight: bold;
  text-transform: uppercase;
}

.ant-steps-item-icon,
.ant-steps-icon {
  color: whitesmoke !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #0991cc !important;
}

.app-tile {
  border: 1px solid #043c54;
  border-radius: 5px;
  text-align: center;
}
.app-tile * {
  color: #666 !important;
}

.summary-card {
  padding: 8px 0 8px 0;
  border-radius: 5px;
  text-align: center;
  transition: all 0.25s linear;
  width: 400px;
}
.summary-card .data {
  color: #0991cc !important;
  font-weight: bolder;
  font-size: 1.8em;
  margin: 0px;
}
.summary-card .card-label {
  color: #777 !important;
  font-size: 0.8em;
}
.summary-card:hover {
  border-color: #0991cc;
  cursor: pointer;
}

.card-danger {
  animation: blinkRed 1s linear infinite;
}
.card-danger .data,
.card-danger .card-label {
  color: whitesmoke !important;
}

.card-warning {
  animation: blinkGold 2s linear infinite;
}
.card-warning .data,
.card-warning .card-label {
  color: #333 !important;
}

.card-alert {
  animation: blinkGreen 3s linear infinite;
}
.card-alert .data,
.card-alert .card-label {
  color: #333 !important;
}

@keyframes blinkRed {
  50% {
    background-color: darkred;
    color: whitesmoke !important;
  }
}
@keyframes blinkGold {
  50% {
    background-color: goldenrod;
  }
}
@keyframes blinkGreen {
  50% {
    background-color: #0991cc;
  }
}
.action-tile {
  transition: all 0.25s linear;
}
.action-tile:hover {
  border-color: #0991cc;
  background-color: #0991cc;
}
.action-tile:hover * {
  color: #ddd !important;
}

.tile-active {
  border-color: #0991cc;
  background-color: #0991cc;
}
.tile-active * {
  color: #ddd !important;
}

.hover:hover {
  cursor: pointer !important;
}

.h-scroll {
  display: flex;
  overflow-x: scroll !important;
  padding-bottom: 10px;
}

@media screen and (min-width: 600px) {
  .h-scroll {
    overflow-x: hidden !important;
    scrollbar-gutter: 1px !important;
    padding-bottom: 1px;
  }
}
.app-btn {
  padding: 8px 18px;
  font-size: 0.9em;
  text-decoration: none;
  border-radius: 5px;
  border: 0.5px solid rgba(9, 145, 204, 0.3);
  background-color: transparent !important;
  transition: all 0.1s linear;
}
.app-btn:hover {
  background-color: #0991cc !important;
  color: whitesmoke !important;
}

.btn-text {
  border: 0px;
  color: #0991cc;
  font-weight: 600;
}
.btn-text:hover {
  background-color: rgba(29, 79, 154, 0.1) !important;
  color: #333 !important;
}

.btn-prim {
  padding: 8px 18px;
  font-size: 0.9em;
  text-decoration: none;
  border-radius: 5px;
  border: 0.5px solid rgba(9, 145, 204, 0.3);
  background-color: transparent !important;
  transition: all 0.1s linear;
  background-color: #0991cc !important;
  color: whitesmoke;
}
.btn-prim:hover {
  color: whitesmoke;
  background-color: rgb(29, 79, 154) !important;
  border: 1px solid rgb(29, 79, 154);
}

.btn-secondary {
  padding: 8px 18px;
  font-size: 0.9em;
  text-decoration: none;
  border-radius: 5px;
  border: 0.5px solid rgba(9, 145, 204, 0.3);
  background-color: transparent !important;
  transition: all 0.1s linear;
  background-color: #efca00 !important;
  color: black;
}
.btn-secondary:hover {
  color: whitesmoke;
  background-color: #776502 !important;
  border: 1px solid #776502;
}

.app-card {
  border: 1px solid rgba(29, 79, 154, 0.4) !important;
  padding: 10px !important;
  border-radius: 15px;
}
.app-card.filter-card {
  border-radius: 20px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.mobile-nav {
  width: 100vw;
  background-color: whitesmoke !important;
  position: fixed;
  bottom: 0;
  border-top: 0.01px solid #ccc !important;
  z-index: 450;
}
.mobile-nav .nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.mobile-nav .nav-links > a {
  padding: 2px 6px 2px 6px;
  text-align: center;
  color: #999;
}
.mobile-nav .nav-links .nav-label {
  font-size: 1em;
  font-weight: normal;
}
.mobile-nav .nav-links .active-nav > * {
  color: #0991cc !important;
}

.desktop-nav {
  height: 100vh;
  padding-left: 20px !important;
  z-index: 50;
  position: fixed;
}
.desktop-nav .nav-links > a {
  display: flex;
  padding: 10px;
  color: #777 !important;
}
.desktop-nav .nav-links > a.active-nav {
  border-radius: 5px;
  color: #0991cc !important;
  font-weight: bold;
}

.top-nav {
  background-color: whitesmoke !important;
  width: 100vw;
  position: fixed;
  z-index: 60;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-nav .input {
  height: 35px !important;
}

.charm-bar {
  top: 0px;
  z-index: 500;
  background-color: whitesmoke;
}
.charm-bar .charm-content {
  top: 0;
}

.quick-page,
.video-consultation {
  background-color: #ddd !important;
}
.quick-page #videoFrame,
.video-consultation #videoFrame {
  position: fixed;
}
.quick-page iframe,
.video-consultation iframe {
  width: 65vw;
  height: 100vh !important;
}

.patient-finder {
  min-height: 80vh !important;
}
.patient-finder .results {
  overflow-y: auto;
}

.user-profile .user-avatar {
  min-width: 30vw !important;
  min-height: 15vh !important;
  margin-top: -60px;
  border: 1px solid #0991cc;
}

.consultation-detail .section-header {
  color: #0991cc !important;
  font-weight: 500;
}
.consultation-detail .layer-header {
  color: #999 !important;
}

.roles-page {
  height: 50vh;
}
.roles-page .content {
  min-height: 70vh;
}
.roles-page .permissions {
  overflow-y: auto;
  border-left: 1px solid #ddd;
}

@media screen and (min-width: 600px) {
  .quick-page .content {
    margin-top: 10vh;
    background-color: whitesmoke;
  }
  .user-profile .user-avatar {
    min-width: 10vw !important;
    min-height: 21vh !important;
    margin-top: -60px;
    border: 2px solid #0991cc;
  }
}
.portal {
  background-color: #ddd;
  min-height: 100vh;
}
.portal .inner-layer {
  background-color: #f5f5f5;
}
.portal .inner-layer .content {
  padding-bottom: 70px;
}

@media screen and (min-width: 768px) {
  .portal .inner-layer {
    background-color: whitesmoke;
    position: relative;
    border-radius: 5px;
  }
}
* {
  margin: 0;
  box-sizing: content-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.app-layout {
  background-color: #ddd !important;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh !important;
}
.app-layout .layout-outlet {
  background-color: whitesmoke !important;
  min-height: 100vh;
  border-radius: 0px;
  padding: 3%;
  position: relative !important;
}

@media screen and (min-width: 600px) {
  .app-layout .content {
    margin-top: 2%;
    position: relative;
  }
  .app-layout .layout-outlet {
    border-radius: 5px;
    border: 2px solid #0aa0e0;
  }
}/*# sourceMappingURL=styles.css.map */