.mobile-nav {
	width: 100vw;
	background-color: whitesmoke !important;
	position: fixed;
	bottom: 0;
	border-top: 0.01px solid #ccc !important;
	z-index: 450;
	// padding: 10px;

	& .nav-links {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
		text-decoration: none;
		transition: all 0.3s ease-in-out;
		// font-size: 1.2rem;
		// font-weight: bold;

		& > a {
			padding: 2px 6px 2px 6px;
			text-align: center;
			color: #999;
		}

		& .nav-label {
			font-size: 1em;
			font-weight: normal;
		}

		& .active-nav > * {
			color: $primary-color !important;
			// background-color: rgba($color: $primary-color, $alpha: 0.2);
			// border-radius: 5px;
			// font-weight: bold;
			// color: #999;

			// padding: 10px;
		}
	}
}

.desktop-nav {
	height: 100vh;
	padding-left: 20px !important;
	z-index: 50;
	position: fixed;

	// border-right: 2px solid #aaa;
	// padding-top: 15% !important;
	// padding: 20px !important;
	// min-width: 20vw !important;
	// background-color: whitesmoke !important;

	& .nav-links {
		& > a {
			display: flex;
			padding: 10px;
			color: #777 !important;

			&.active-nav {
				// background-color: rgba($color: $primary-color, $alpha: 0.2);
				border-radius: 5px;
				color: $primary-color !important;
				font-weight: bold;
			}
		}
	}
}

.top-nav {
	background-color: whitesmoke !important;
	width: 100vw;
	position: fixed;
	z-index: 60;

	display: flex;
	align-items: center;
	justify-content: space-between;

	& .input {
		height: 35px !important;
	}
}
